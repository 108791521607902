import '../Styles/cartproduct.css';
import close from '../Assets/Close.svg';
import { useState, useEffect } from 'react';

export default function CartProduct(props) {

  const [counter, setCounter] = useState(props.card.quantity);
  const [cart, setCart] = useState([]);

  // Load cart from local storage when the component mounts
  useEffect(() => {
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      setCart(storedCart);
  }, []);

  const updateLocalStorage = (updatedCart) => {
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      setCart(updatedCart);
  };

  const handleClick1 = () => {
      const updatedCounter = counter + 1;
      setCounter(updatedCounter);
      props.onCounterUpdate(props.card.productId, updatedCounter);
      const updatedCart = cart.map(item =>
          item.productId === props.card.productId
              ? { ...item, quantity: updatedCounter }
              : item
      );

      updateLocalStorage(updatedCart);
  };

  const handleClick2 = () => {
      if (counter > 1) {
          const updatedCounter = counter - 1;
          setCounter(updatedCounter);
          props.onCounterUpdate(props.card.productId, updatedCounter);
          const updatedCart = cart.map(item =>
              item.productId === props.card.productId
                  ? { ...item, quantity: updatedCounter }
                  : item
          );

          updateLocalStorage(updatedCart);
      }
  };

  const remove = () => {
      const updatedCart = cart.filter(item => item.productId !== props.card.productId);
      updateLocalStorage(updatedCart);
      props.onCounterUpdate(props.card.productId, 0);
  };
    
    return(
        <div className="ct-wrp">
            <div className="img-txt">
                <div className='small-img'>
                    <img  src={props.card.image} alt=''/>
                </div>
                
                <p>{props.name}</p>
            </div>
            <p className='prc'>${props.card.price}</p>
            <div className='add-cart-btns'>
                <div>
                            <button onClick={handleClick2}>-</button>
                            <p>
                            {counter}
                            </p>
                            <button onClick={handleClick1}>+</button>
                            </div>
            </div>
            <p className='sb-ttl'>${counter * props.card.price}</p>
            <button onClick={remove} className='rmv-prd-btn'><img src={close} alt='' /></button>
        </div>
    );
}

