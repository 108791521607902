import '../Styles/sidecart.css';
import { useState,useEffect, useRef } from 'react';
//import Popup from 'reactjs-popup';
import { Link, useNavigate} from "react-router-dom";
import CartProduct from './CartProduct';
import { useDispatch } from 'react-redux';
import { newCartWindowState } from '../features/sideCartControl';

export default function SideCart() {

  const dispatch = useDispatch();
  const [carted, setCarted] = useState([]);
  const [total, setTotal] = useState("");
  const sideCartRef = useRef(null); // Create a ref for the SideCart
  const navigate = useNavigate();

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCarted(storedCart);
    calculateTotal(storedCart);
  }, []);

  const calculateTotal = (cartItems) => {
    const totalAmount = cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0);
    setTotal(totalAmount);
  };

  const handleCounterUpdate = (productId, newCounter) => {
    const updatedCart = carted.map(item =>
      item.productId === productId ? { ...item, quantity: newCounter } : item
    );
    setCarted(updatedCart);
    calculateTotal(updatedCart);
  };

    // Handle clicks outside of the SideCart
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (sideCartRef.current && !sideCartRef.current.contains(event.target)) {
          dispatch(newCartWindowState(false)); // Close the SideCart
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dispatch]);

  const cartedItems = carted.length > 0
    ? carted.filter((card) => card.quantity > 0).map((card) => (
        <CartProduct key={card.id} card={card} onCounterUpdate={handleCounterUpdate}  /> // Add unique key for each item
      ))
    : []; // Return empty array if carted is empty
    console.log(carted)

    function handleNavigate() {
      const mappedData = carted.map(item => ({
          id: item.id,
          quantity: item.quantity
      }));
      console.log(mappedData);
      dispatch(newCartWindowState(false));
      navigate("/checkout")
  }
    
    return(
        <div ref={sideCartRef} className='sc-wrapper'>
            <div className='sc-header'>
                <p>
                     Shopping Cart ({carted.length})
                </p>
                <svg onClick={()=>{dispatch(newCartWindowState(false))}}  xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path d="M18.75 6.25L6.25 18.75" stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.25 6.25L18.75 18.75" stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
             <div>
             {cartedItems}
             </div>
            
            <div className='sc-bott-ctn'>
            <div className='sc-bott'>
            <div className='sc-preview'>
            <p>{carted.length} Products</p>
            <p>${total}</p>
            </div>  
            <div className='sc-btns'>
            <button className='sc-check' onClick={handleNavigate} >Checkout</button>
            <Link to="/cart">  <button className='sc-gtc' onClick={()=>{dispatch(newCartWindowState(false))}} >Go to Cart</button></Link>
            </div>
            </div>
            </div>
           
        </div>
    );
}