//import NavBar from '../../src/Components/NavBar';
import Header from '../../src/Components/Header';
import Hero from '../../src/Components/Hero';
import Footer from '../../src/Components/Footer';

export default function Home() {
    return(<div>
        {/* <NavBar /> */}
         <Header />
         <Hero />
        <Footer />
    </div>);
}