import Footer from "../Components/Footer";
import {  Link, useNavigate } from "react-router-dom"
import CartProduct from "../Components/CartProduct";
import React, { useState, useEffect } from "react";
import '../Styles/cart.css'

export default function Cart() {
    const [getCart, setGetCart] = useState([]);
    const [cartTotal, setCartTotal] = useState("")


    const navigate = useNavigate();

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setGetCart(storedCart);
        calculateTotal(storedCart);
      }, []);
    
      const calculateTotal = (cartItems) => {
        const totalAmount = cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0);
        setCartTotal(totalAmount);
      };
    
      const handleCounterUpdate = (productId, newCounter) => {
        const updatedCart = getCart.map(item =>
          item.productId === productId ? { ...item, quantity: newCounter } : item
        );
        setGetCart(updatedCart);
        calculateTotal(updatedCart);
      };

 
   
    let Shipping = 5;
    // need to update this with session storage

    const cart = getCart.length > 0
    ? getCart.filter((card) => card.quantity > 0).map((card) => (
        <CartProduct key={card.id} card={card} onCounterUpdate={handleCounterUpdate}  /> // Add unique key for each item
      ))
    : []; // Return empty array if carted is empty

    function handleNavigate() {
        const mappedData = getCart.map(item => ({
            id: item.id,
            quantity: item.quantity
        }));
        console.log(mappedData)
        navigate("/checkout")
    }
    return(
        <div className="cart-wrapper">

            <div className="crt-title">
            <p>My Shopping Cart</p>
            </div>
            
          <div className="cart-bd">
                
                <br/>
                <main className="crt-mn">
                    <div className="top-cart">
                        <div className="crt-hd">
                            <p className="prd">Product</p>
                            <p className="pric">Price</p>
                            <p className="qty">Quantity</p>
                            <p className="sbt">SubTotal</p>
                        </div>
                            <div className="crt-st-ctn">
                            {cart}
                            </div>
                        <div className="crt-actns">
                            <Link to='/shop' className="rts-btn"><button>Return to shop</button></Link>
                            {/* <button onClick={()=>{window.location.reload()}}>Update cart</button> */}
                        </div>
                    </div>
                    <div className="btm-crt">
                       <p>Coupon Code</p>
                       <form>
                        <input type="text" />
                        <button>Apply Coupon</button>
                       </form>
                    </div>
                </main>
                <aside>
                    <p className="crt-ttl">Cart Total</p>
                    <div>
                        <p className="crt-light">Subtotal:</p>
                        <p className="crt-bld">${cartTotal}</p>
                    </div>
                    <div>
                        <p className="crt-light">Shipping:</p>
                        <p className="crt-bld">Free</p>
                    </div>
                    <div>
                        <p className="crt-light">Total:</p>
                        <p className="crt-bld">$ {cartTotal + Shipping}</p>
                    </div>
                    <button className="ptd" onClick={handleNavigate}>Procced to Checkout</button>
                </aside>
          </div>
 
        <Footer />
        </div>
    );
}